import { Card, Row, Col } from "antd";
import {
  FileAddOutlined,
  DeleteOutlined,
  LeftSquareOutlined,
} from "@ant-design/icons";
function Kaam() {
  return (
    <div>
      <Row style={{ height: "86vh" }}>
        <Col className="container-column" flex="150px">
          <Card
            size="small"
            title="Pages"
            actions={[
              <FileAddOutlined key="addPage" />,
              <DeleteOutlined key="deletePage" />,
            ]}
            bodyStyle={{ padding: "2px 0px 5px 5px" }}
          >
            <div style={{ height: "73vh", overflowY: "scroll" }}>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
            </div>
          </Card>
        </Col>
        <Col className="container-column" flex="auto">
          <Card
            size="small"
            title="Workspace"
            extra={<span className="span-for-link">More</span>}
            style={{ width: "100%" }}
          >
            <div style={{ height: "77.2vh", overflow: "scroll" }}></div>
          </Card>
        </Col>
        <Col className="container-column" flex="150px">
          <Card
            size="small"
            title="Data Source/Sink"
            actions={[
              <span>
                <LeftSquareOutlined key="openStore" /> Open
              </span>,
            ]}
            bodyStyle={{ padding: "2px 0px 5px 5px" }}
          >
            {/* Show a bullet which include business logic */}
            <div style={{ height: "73.2vh", overflowY: "scroll" }}>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
              <p>Card content</p>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Kaam;
