import axios from "axios";

export default function axiosInstance() {
  axios.defaults.baseURL =
    window.location.hostname === "localhost"
      ? "/vyvstha-mgmt/public/api/"
      : "/public/api/";
  axios.defaults.withCredentials = true;
  // axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  // axios.defaults.headers.common["Access-Control-Allow-Methods"] =
  //   "GET, POST, PATCH, PUT, DELETE, OPTIONS";
  // axios.defaults.headers.common["Access-Control-Allow-Headers"] =
  //   "Origin, Content-Type, X-Auth-Token";
}
