import React, { Component } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import {
  Card,
  Divider,
  Row,
  Col,
  Carousel,
  Typography,
  Image,
  Form,
  Input,
  Button,
  Checkbox,
} from "antd";
import { UserOutlined, LockOutlined, SmileOutlined } from "@ant-design/icons";
import {
  FacebookLoginButton,
  GoogleLoginButton,
  LinkedInLoginButton,
} from "react-social-login-buttons";

import { login, signup, tryWithoutLogin } from "./actions";
import "./style.css";

import logo_text from "../../images/logo_text.png";
import bannerImage from "../../images/banner.jpg";
import logo_vector from "../../images/only_vector.png";

class LoginComponent extends Component {
  moveToLogin = () => {
    this.carousel.prev();
  };

  moveToSignup = () => {
    this.carousel.next();
  };

  render() {
    const { Title } = Typography;
    const commonStyle = {
      textAlign: "center",
    };
    const loginToggle = {
      cursor: "pointer",
      width: "290px",
      margin: "auto",
    };
    const socialMediaButton = {
      width: "300px",
      height: "32px",
      fontSize: "12px",
      textAlign: "center",
      paddingLeft: "13%",
      marginTop: "7px",
    };
    const onLogin = (values) => {
      console.log("Received values of form: ", values);
    };
    const onSignup = (values) => {
      console.log("Received values of form: ", values);
    };
    const onGoogleLogin = () => {
      console.log("onGoogleLogin");
    };
    const onLinkedinLogin = () => {
      console.log("onLinkedinLogin");
    };
    const onFacebookLogin = () => {
      console.log("onFacebookLogin");
    };

    const skippRegistraionToTry = () => {
      console.log("skippRegistraionToTry");
      this.props.tryWithoutLogin();
    };

    if (this.props.user.id) {
      return <Navigate to="/" replace={true} />;
    }

    return (
      <div
        className="main-container"
        style={{ height: "100vh", display: "flex", alignItems: "center" }}
      >
        <Card
          style={{
            margin: "auto",
            width: "70%",
            height: "84vh",
            boxShadow: "0px 0px 4px -2px",
            borderRadius: "5px",
          }}
        >
          <Row style={{ height: "76vh", overflow: "hidden" }}>
            <Col
              span={12}
              style={{
                borderRight: "solid 1px silver",
                textAlign: "center",
                paddingTop: "18px",
              }}
            >
              <Image
                style={{ paddingBottom: "5px" }}
                width={185}
                src={logo_text}
                preview={false}
              />
              <Image src={bannerImage} preview={false} />
              <Title level={5} style={{ padding: "50px" }}>
                Vystha help's you to get your software from Idea to a Market Fit
                Product. If you reached here for first time, We would request
                you to try it. It is Free to try.
              </Title>
            </Col>
            <Col span={12} style={{ textAlign: "center" }}>
              <Image
                width={80}
                src={logo_vector}
                preview={false}
                style={{ marginBottom: "10px" }}
              />
              <Carousel
                ref={(slider) => (this.carousel = slider)}
                effect="fade"
                dots={false}
              >
                <div>
                  <div style={commonStyle}>
                    <Title level={3}>Login</Title>
                    <Form
                      name="normal_login"
                      className="login-form"
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onLogin}
                    >
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Email!",
                          },
                        ]}
                      >
                        <Input
                          prefix={
                            <UserOutlined className="site-form-item-icon" />
                          }
                          placeholder="Email"
                        />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Password!",
                          },
                        ]}
                      >
                        <Input
                          prefix={
                            <LockOutlined className="site-form-item-icon" />
                          }
                          type="password"
                          placeholder="Password"
                        />
                      </Form.Item>
                      <Form.Item>
                        <Form.Item
                          name="remember"
                          valuePropName="checked"
                          noStyle
                        >
                          <Checkbox>Remember me</Checkbox>
                        </Form.Item>

                        <span
                          className="login-form-forgot"
                          style={{ cursor: "pointer" }}
                        >
                          Forgot password
                        </span>
                      </Form.Item>

                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="login-form-button"
                        >
                          Log in
                        </Button>
                      </Form.Item>
                    </Form>
                    <Title
                      level={5}
                      type="warning"
                      onClick={() => {
                        this.moveToSignup();
                      }}
                      style={loginToggle}
                    >
                      Not signed up, Click here to Signup
                    </Title>
                  </div>
                </div>
                <div>
                  <div style={commonStyle}>
                    <Title level={3}>Signup</Title>
                    <Form
                      name="normal_login"
                      className="login-form"
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onSignup}
                    >
                      <Form.Item
                        name="fullname"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Full Name!",
                          },
                        ]}
                      >
                        <Input
                          prefix={
                            <SmileOutlined className="site-form-item-icon" />
                          }
                          placeholder="Full Name"
                        />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Email!",
                          },
                        ]}
                      >
                        <Input
                          prefix={
                            <UserOutlined className="site-form-item-icon" />
                          }
                          placeholder="Email"
                        />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Password!",
                          },
                        ]}
                      >
                        <Input
                          prefix={
                            <LockOutlined className="site-form-item-icon" />
                          }
                          type="password"
                          placeholder="Password"
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="login-form-button"
                        >
                          Signup
                        </Button>
                      </Form.Item>
                    </Form>
                    <Title
                      level={5}
                      type="warning"
                      onClick={() => {
                        this.moveToLogin();
                      }}
                      style={loginToggle}
                    >
                      Already signed up, Click here to Login
                    </Title>
                  </div>
                </div>
              </Carousel>
              <div style={{ textAlign: "-webkit-center" }}>
                <Divider
                  plain
                  style={{ padding: "0px 100px", margin: "5px 10px 18px 10px" }}
                >
                  <Title level={5}>or</Title>
                </Divider>
                <GoogleLoginButton
                  text="Continue with Google"
                  style={socialMediaButton}
                  onClick={() => onGoogleLogin()}
                />
                <LinkedInLoginButton
                  text="Continue with LinkedIn"
                  style={socialMediaButton}
                  onClick={() => onLinkedinLogin()}
                />
                <FacebookLoginButton
                  text="Continue with Facebook"
                  style={socialMediaButton}
                  onClick={() => onFacebookLogin()}
                />
              </div>
            </Col>
          </Row>
          <div style={{ textAlign: "right" }}>
            <Title
              level={5}
              onClick={() => {
                skippRegistraionToTry();
              }}
              style={{ cursor: "pointer", color: "blue" }}
            >
              &gt;&gt; Skip Registration for now and try for free!!
            </Title>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
  };
};

export default connect(mapStateToProps, { login, signup, tryWithoutLogin })(
  LoginComponent
);
