import { useState } from "react";
import { Breadcrumb, Row, Col, Menu, Image } from "antd";
import {
  SettingOutlined,
  BulbOutlined,
  PlayCircleOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";

import logo_text from "../images/logo_text.png";
// import logo_vector from "../images/only_vector.png";

const breadcrumbNameMap = {
  "/dashboard": "Dashboard",
  "/dashboard/soch": "Soch",
  "/dashboard/kaam": "Kaam",
  "/dashboard/kaaj": "Kaaj",
};

function NavBar(props) {
  const { SubMenu } = Menu;
  let [current, setCurrent] = useState("Kaam");
  const location = useLocation();
  let navigate = useNavigate();

  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    );
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">
        <HomeOutlined />
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  const handleDashboardClick = (e) => {
    setCurrent(e.key);
    navigate("/dashboard/" + e.key);
  };

  const handleClick = (e) => {
    setCurrent(e.key);
    navigate("/" + e.key);
  };

  return (
    <div>
      <Row
        style={{
          boxShadow: "0px 1px 2px rgb(100,100,100,0.25)",
          marginBottom: "6px",
        }}
      >
        <Col span={2}>
          <Image
            width={100}
            src={logo_text}
            preview={false}
            style={{ margin: "10px" }}
          />
        </Col>
        <Col span={20}>
          <Menu
            onClick={handleDashboardClick}
            selectedKeys={[current]}
            mode="horizontal"
          >
            <Menu.Item key="logo"></Menu.Item>
            <Menu.Item key="soch" icon={<BulbOutlined />}>
              Soch
            </Menu.Item>

            <Menu.Item key="kaam" icon={<SettingOutlined />}>
              Kaam
            </Menu.Item>
            <Menu.Item key="kaaj" icon={<PlayCircleOutlined />}>
              Kaaj
            </Menu.Item>
          </Menu>
        </Col>
        <Col span={2}>
          <Menu
            onClick={handleClick}
            selectedKeys={[current]}
            mode="horizontal"
          >
            <SubMenu key="SubMenu" icon={<SettingOutlined />} title="Account">
              <Menu.ItemGroup title="Third Party">
                <Menu.Item key="integration">Integrations</Menu.Item>
                <Menu.Item key="credentials">Credentials</Menu.Item>
              </Menu.ItemGroup>
              <Menu.ItemGroup title="Management">
                <Menu.Item key="billings">Billings</Menu.Item>
                <Menu.Item key="account">Account Info</Menu.Item>
                <Menu.Item key="settings">Setting</Menu.Item>
              </Menu.ItemGroup>
            </SubMenu>
          </Menu>
        </Col>
      </Row>
      <Breadcrumb style={{margin: "0px 12px 3px 10px"}}>{breadcrumbItems}</Breadcrumb>
    </div>
  );
}

export default NavBar;
