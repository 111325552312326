import axios from "axios";
export const tryWithoutLogin = () => {
  return (dispatch) => {
    axios
      .post("/tryWithoutLogin")
      .then((res) => {
        dispatch({ type: "TRY_WITHOUT_LOGIN", data: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const login = ({ username, password }) => {
  return (dispatch) => {
    axios
      .post("/login", {
        username,
        password,
      })
      .then((res) => {
        dispatch({ type: "USER_LOGIN", data: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const signup = ({ username, password }) => {
  return (dispatch) => {
    axios
      .post("/signup", {
        username,
        password,
      })
      .then((res) => {
        dispatch({ type: "USER_SIGNUP", data: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
