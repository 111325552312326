import * as React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";

import "antd/dist/antd.min.css";
import "./App.css";

import NavBar from "./components/navbar";

import Login from "./containers/Login";
import HomePage from "./containers/HomePage";

import Soch from "./containers/HomePage/components/soch";
import Kaam from "./containers/HomePage/components/kaam";
import Kaaj from "./containers/HomePage/components/kaaj";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <React.Suspense fallback={<>...</>}>
                <NavBar />
                <HomePage />
              </React.Suspense>
            </RequireAuth>
          }
        >
          <Route
            path="soch"
            element={
              <React.Suspense fallback={<>...</>}>
                <Soch />
              </React.Suspense>
            }
          />
          <Route
            path="kaam"
            element={
              <React.Suspense fallback={<>...</>}>
                <Kaam />
              </React.Suspense>
            }
          />
          <Route
            path="kaaj"
            element={
              <React.Suspense fallback={<>...</>}>
                <Kaaj />
              </React.Suspense>
            }
          />
        </Route>
        <Route path="/login" element={<Login />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

function RequireAuth({ children }) {
  const user = useSelector((state) => state.authReducer.user);
  let location = useLocation();

  if (!user.id) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
