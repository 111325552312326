import axios from "axios";
export const getMyContent = () => {
  return (dispatch) => {
    axios
      .get("/mycontent")
      .then((res) => {
        dispatch({ type: "MY_CONTENT", data: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};