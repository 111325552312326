import { Component } from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import { getMyContent } from "./action";

import './style.css';
class HomePage extends Component {
  componentDidMount() {
    this.props.getMyContent();
  }

  render() {
    return (
      <>
        <Outlet />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
  };
};

export default connect(mapStateToProps, { getMyContent })(HomePage);
